<template>
  <Toast position="top-right" />
  <ConfirmDialog :breakpoints="{ '1800px': '35vw', '1350px': '35vw', '960px': '50vw', '640px': '98vw' }"
    :style="{ width: '25vw' }"></ConfirmDialog>
  <div class="p-shadow p-bg-white p-container" style="min-height:100%;">
    <div class="p-d-flex p-jc-between">
      <span class="p-text-responsive">INSCRIPCIONES</span>
    </div>
    <div class="p-grid">
      <div class="p-col-12 p-d-flex p-mt-4" style="">
        <div class="p-list-group p-list-group-flush p-col-12 p-px-0">
          <div class="responsive p-list-group-item p-pb-3 p-d-flex p-jc-between p-ai-center"
            style="font-size:11pt !important; font-weight:500 !important; color:#484848 !important;">
            <div nombre_curso style="width:400px;">
              Nombre
            </div>
            <div fecha_pago style="width:120px;">
              <span>Inscripción</span>
            </div>
            <div cuotas style="width:100px;">
              <span>Cuotas</span>
            </div>
            <div precio style="width:100px;">
              <span>Tipo</span>
            </div>
            <div opciones style="width:140px;">
            </div>
          </div>
          <div v-if="lista.length == 0 && !lista_is_loading" class="p-list-group-item p-text-center">
            <span class="text-muted">NO HAY INSCRIPCIONES DE CURSOS</span>
          </div>
          <div v-if="lista_is_loading" class="p-list-group-item p-text-center">
            <span><i class="pi pi-spin pi-spinner"></i>&nbsp;CARGANDO INSCRIPCIONES</span>
          </div>
          <div v-for="item in lista" :key="item"
            class="responsive-item-list p-list-group-item p-py-2 p-d-flex p-jc-between p-ai-center"
            style="font-size:11pt !important;">
            <div nombre_curso style="width:400px;">
              <i class="pi pi-check-circle p-mr-2"></i>
              <span>{{ item.nombre }}</span>
            </div>
            <div fecha_pago style="width:120px;">
              <span small_visible>Inscripción: </span>
              <span>{{ item.fecha }}</span>
            </div>
            <div cuotas style="width:100px;">
              <span small_visible>Cuotas: </span>
              <span>{{ item.cuotas }}</span>
            </div>
            <div precio style="width:100px;">
              <span small_visible>Tipo: </span>
              <span><i class="pi p-mr-2"></i> {{ item.tipo }}</span>
            </div>
            <div opciones style="width:140px;">
              <Button @click="generar_comprobante(item.codigo_pago, item.codigo_cliente)" label="Comprobante"
                class="hide p-button-outlined p-button-secondary p-button-sm"></Button>
              <Button v-if="item.precio == 0" icon="pi pi-print" disabled="disabled"
                class="p-button-outlined p-button-secondary p-button-sm"></Button>
              <Button v-else icon="pi pi-print" @click="comprobantes(item.codigo)"
                class="p-button-outlined p-button-secondary p-button-sm"></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog header="COMPROBANTE" @hide="urlPdf = ''" :position="'top'" v-model:visible="modal_comprobante"
    :style="{ width: '50vw' }" :draggable="false" closeOnEscape="true" :modal="true">
    <p v-if="comprobante_is_loading" class="p-text-center"><i class="pi pi-spin pi-spinner"></i>&nbsp;<span>CARGANDO
        COMPROBANTE</span></p>
    <object type="application/pdf" :data="urlPdf" style="width:100%; height:500px;">
    </object>
  </Dialog>
</template>

<style scoped>
.white span.p-button-label,
span.p-button-icon {
  color: #d4d4d4 !important;
}

@media (min-width: 750px) {
  span[small_visible] {
    display: none !important;
  }
}

@media (max-width: 750px) {
  .responsive {
    display: none !important;
  }

  span[small_visible] {
    display: block !important;
    width: 110px !important;
    font-weight: 500 !important;
  }

  .responsive-item-list {
    flex-wrap: wrap;
    padding: 20px 17.5px !important;
  }

  div[nombre_curso] {
    width: 100% !important;
    font-size: 11pt !important;
    text-align: center !important;
    margin-bottom: 10px !important;
  }

  div[opciones] {
    width: 100% !important;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  div[opciones] button {
    display: flex;
    justify-content: center;
    width: 96%;
    margin: auto;
  }

  div[opciones] button[short] {
    width: 50px;
    margin-left: 5px;
  }

  div[opciones] button>.p-button-label {
    font-size: 11pt !important;
    text-transform: uppercase;
  }

  div[fecha_pago] {
    width: 96% !important;
    margin: auto;
    margin-bottom: 3px;
    font-size: 11pt;
    display: flex !important;
  }

  div[cuotas] {
    width: 96% !important;
    margin: auto;
    margin-bottom: 3px;
    font-size: 11pt;
    display: flex !important;
  }

  div[precio] {
    width: 96% !important;
    margin: auto;
    margin-bottom: 3px;
    font-size: 11pt;
    display: flex !important;
  }

  div[tipo_pago] {
    width: 96% !important;
    margin: auto;
    font-size: 11pt;
    display: flex !important;
  }

  div[cuotas] button {
    display: flex !important;
    justify-content: flex-start;
    width: 96% !important;
    margin: auto;
    margin-bottom: 3px;
    font-size: 11pt;
  }
}
</style>

<script>
export default {
  data() {
    return {
      lista: [],
      lista_is_loading: false,
      comprobante_is_loading: false,
      modal_comprobante: false,
      urlPdf: '',
    }
  },
  methods: {
    comprobantes(codigo) {
      this.$router.push("pagos?historial=" + codigo);
    },
    async generar_comprobante(codigo, cliente) {
      this.modal_comprobante = true;
      this.comprobante_is_loading = true;
      await this.axios.get("/Cursos/ComprobantePago/" + codigo + "/" + cliente, { responseType: 'blob' })
        .then(async response => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          this.blobToBase64(blob);
        })
        .catch(() => { })
        .then(() => { this.comprobante_is_loading = false; })
    },
    blobToBase64(blob) {
      var reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.urlPdf = reader.result;
      }
    },
    async traer_lista() {
      this.lista_is_loading = true;
      await this.axios.get("/Cursos/Inscritos")
        .then(r => { this.lista = r.data; })
        .catch(() => { })
        .then(() => { this.lista_is_loading = false; })
    },
  },
  mounted() {
    this.traer_lista();
  },
}
</script>
